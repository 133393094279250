		<div class="col-md-12">
			<button class="aerial-action-button pull-right" (click)="changeEditable()" *ngIf="!editable"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">mode_edit</i></button>
			<button class="aerial-action-button pull-right"(click)="cancel()" *ngIf="editable"><i class="material-icons aerial-color-red aerial-action-button-icon">close</i></button>
			<button class="aerial-action-button pull-right" (click)="save()" *ngIf="editable"><i class="material-icons aerial-color-blue aerial-action-button-icon">done</i></button>
		</div>
		<div class="col-md-12">
			<div class="col-md-6">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-16">directions_walk</span>
						<span class="aerial-area-icon-text-14">{{'aerial.user.notifications.activity' | translate}}</span>
						<input type="checkbox" style="margin-left:10px;margin-top:8px;float:right" [(ngModel)]="userComplete.notificationSetUp.activityEnabled" [disabled]="!editable" />
						<span style="float:right;margin-top:6px;" class="aerial-sublabel aerial-color-blue" [ngClass]="{'aerial-color-blue':userComplete.notificationSetUp.activityEnabled,'aerial-color-grey':!userComplete.notificationSetUp.activityEnabled}">{{'aerial.active' | translate}}</span>
					</div>
					<div class="aerial-field">
						<span class="aerial-sublabel">{{'aerial.user.notifications.activity.indications' | translate}}</span>	
					</div>
				</div>
				<div class="col-md-6 aerial-field-section">
					<div class="aerial-field">
						<span class="aerial-sublabel aerial-color-blue">{{'aerial.from' | translate}}:</span>
						<select [(ngModel)]="userComplete.notificationSetUp.activityStartTime" [disabled]="!editable">
							 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-6 aerial-field-section">
					<div class="aerial-field">
						<span class="aerial-sublabel aerial-color-blue">{{'aerial.to' | translate}}:</span>
						<select [(ngModel)]="userComplete.notificationSetUp.activityEndTime" [disabled]="!editable">
							 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-field">
						<span class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.activity.inactivityTime' | translate}}</span>	
						<select [(ngModel)]="userComplete.notificationSetUp.activityLapseTime" [disabled]="!editable">
							 <option [value]="hour.value" *ngFor="let hour of hourList">{{hour.name}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<span style="float:left;" class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.activity.restartAlert' | translate}}</span>
					<input type="checkbox" style="margin-top:1px;margin-left:10px;" [(ngModel)]="userComplete.notificationSetUp.activityRestartNotification" [disabled]="!editable"/>
				</div>
			</div>
			<div class="col-md-6">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons-outlined aerial-area-icon-16">sunny</span>
						<span class="aerial-area-icon-text-14">{{'aerial.user.notifications.activityWakeUp' | translate}}</span>
						<input type="checkbox" style="margin-left:10px;margin-top:8px;float:right" [(ngModel)]="userComplete.notificationSetUp.wakeUpEnabled" [disabled]="!editable"/>
						<span style="float:right;margin-top:6px;" class="aerial-sublabel aerial-color-blue" [ngClass]="{'aerial-color-blue':userComplete.notificationSetUp.wakeUpEnabled,'aerial-color-grey':!userComplete.notificationSetUp.wakeUpEnabled}">{{'aerial.active' | translate}}</span>
					</div>
					<div class="aerial-field">
						<span class="aerial-sublabel">{{'aerial.user.notifications.activityWakeUp.indications' | translate}}</span>	
					</div>
				</div>
				<div class="col-md-6 aerial-field-section">
					<div class="aerial-field">
						<span class="aerial-sublabel aerial-color-blue">{{'aerial.from' | translate}}:</span>
						<select [(ngModel)]="userComplete.notificationSetUp.wakeUpStartTime" [disabled]="!editable">
							 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-6 aerial-field-section">
					<div class="aerial-field">
						<span class="aerial-sublabel aerial-color-blue">{{'aerial.to' | translate}}:</span>
						<select [(ngModel)]="userComplete.notificationSetUp.wakeUpEndTime" [disabled]="!editable">
							 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<span style="float:left;" class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.wakeUpctivity.restartAlert' | translate}}</span>
					<input type="checkbox" style="margin-top:1px;margin-left:10px;" [(ngModel)]="userComplete.notificationSetUp.wakeUpRestartNotification" [disabled]="!editable" />
				</div>
			</div>
		</div>
		<div class="col-md-12" *ngIf="displayAlerts">
			<div *ngFor="let alertConfigInfo of userComplete.alertConfigList">
				<div class="col-md-6" *ngIf="alertConfigInfo.alertTypeCode=='HIGH_ACTIVITY'">
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-label">
							<span class="material-icons-outlined aerial-area-icon-16">detection_and_zone</span>
							<span class="aerial-area-icon-text-14">{{'aerial.user.notifications.highLevelActivity' | translate}}</span>
							<input type="checkbox" style="margin-left:10px;margin-top:8px;float:right" [(ngModel)]="alertConfigInfo.activityEnabled" [disabled]="!editable" />
							<span style="float:right;margin-top:6px;" class="aerial-sublabel aerial-color-blue" [ngClass]="{'aerial-color-blue':alertConfigInfo.activityEnabled,'aerial-color-grey':!alertConfigInfo.activityEnabled}">{{'aerial.active' | translate}}</span>
						</div>
						<div class="aerial-field">
							<span class="aerial-sublabel">{{'aerial.user.notifications.highLevelActivity.indications' | translate}}</span>	
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.from' | translate}}:</span>
							<select [(ngModel)]="alertConfigInfo.startTime" [disabled]="!editable">
								 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.to' | translate}}:</span>
							<select [(ngModel)]="alertConfigInfo.endTime" [disabled]="!editable">
								 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.highLevelActivity.threshold' | translate}}</span>	
							<input type="number" [disabled]="!editable"  [(ngModel)]="alertConfigInfo.thresholdValue" />
						</div>
					</div>
				</div>
				<div class="col-md-6" *ngIf="alertConfigInfo.alertTypeCode=='DOOR_ACTIVITY'">
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-label">
							<span class="material-icons-outlined aerial-area-icon-16">door_open</span>
							<span class="aerial-area-icon-text-14">{{'aerial.user.notifications.mainDoorActivity' | translate}}</span>
							<input type="checkbox" style="margin-left:10px;margin-top:8px;float:right" [(ngModel)]="alertConfigInfo.activityEnabled" [disabled]="!editable" />
							<span style="float:right;margin-top:6px;" class="aerial-sublabel aerial-color-blue" [ngClass]="{'aerial-color-blue':alertConfigInfo.activityEnabled,'aerial-color-grey':!alertConfigInfo.activityEnabled}">{{'aerial.active' | translate}}</span>
						</div>
						<div class="aerial-field">
							<span class="aerial-sublabel">{{'aerial.user.notifications.mainDoorActivity.indications' | translate}}</span>	
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.from' | translate}}:</span>
							<select [(ngModel)]="alertConfigInfo.startTime" [disabled]="!editable">
								 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.to' | translate}}:</span>
							<select [(ngModel)]="alertConfigInfo.endTime" [disabled]="!editable">
								 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.mainDoorActivity.device' | translate}}</span>	
							<select [(ngModel)]="alertConfigInfo.relatedDeviceId" [disabled]="!editable">
								<option [value]="" > </option> 
								<option [value]="device.deviceId" *ngFor="let device of userComplete.deviceList">{{device.name}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="col-md-6" *ngIf="alertConfigInfo.alertTypeCode=='HUMIDITY'">
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-label">
							<span class="material-icons-outlined aerial-area-icon-16">humidity_high</span>
							<span class="aerial-area-icon-text-14">{{'aerial.user.notifications.humidity' | translate}}</span>
							<input type="checkbox" style="margin-left:10px;margin-top:8px;float:right" [(ngModel)]="alertConfigInfo.activityEnabled" [disabled]="!editable" />
							<span style="float:right;margin-top:6px;" class="aerial-sublabel aerial-color-blue" [ngClass]="{'aerial-color-blue':alertConfigInfo.activityEnabled,'aerial-color-grey':!alertConfigInfo.activityEnabled}">{{'aerial.active' | translate}}</span>
						</div>
						<div class="aerial-field">
							<span class="aerial-sublabel">{{'aerial.user.notifications.humidity.indications' | translate}}</span>	
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.from' | translate}}:</span>
							<select [(ngModel)]="alertConfigInfo.startTime" [disabled]="!editable">
								 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.to' | translate}}:</span>
							<select [(ngModel)]="alertConfigInfo.endTime" [disabled]="!editable">
								 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.humidity.threshold' | translate}}</span>	
							<input type="number" [disabled]="!editable"  [(ngModel)]="alertConfigInfo.thresholdValue" />
						</div>
					</div>
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.humidity.device' | translate}}</span>	
							<select [(ngModel)]="alertConfigInfo.relatedDeviceId" [disabled]="!editable">
								<option [value]="" > </option> 
								<option [value]="device.deviceId" *ngFor="let device of userComplete.deviceList">{{device.name}}</option>
							</select>
						</div>
					</div>
				</div>	
				<div class="col-md-6" *ngIf="alertConfigInfo.alertTypeCode=='TEMPERATURE'">
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-label">
							<span class="material-icons-outlined aerial-area-icon-16">thermostat</span>
							<span class="aerial-area-icon-text-14">{{'aerial.user.notifications.temperature' | translate}}</span>
							<input type="checkbox" style="margin-left:10px;margin-top:8px;float:right" [(ngModel)]="alertConfigInfo.activityEnabled" [disabled]="!editable" />
							<span style="float:right;margin-top:6px;" class="aerial-sublabel aerial-color-blue" [ngClass]="{'aerial-color-blue':alertConfigInfo.activityEnabled,'aerial-color-grey':!alertConfigInfo.activityEnabled}">{{'aerial.active' | translate}}</span>
						</div>
						<div class="aerial-field">
							<span class="aerial-sublabel">{{'aerial.user.notifications.temperature.indications' | translate}}</span>	
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.from' | translate}}:</span>
							<select [(ngModel)]="alertConfigInfo.startTime" [disabled]="!editable">
								 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.to' | translate}}:</span>
							<select [(ngModel)]="alertConfigInfo.endTime" [disabled]="!editable">
								 <option [value]="time" *ngFor="let time of timeList">{{time | datetime:'time'}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.temperature.low.threshold' | translate}}</span>	
							<input type="number" [disabled]="!editable"  [(ngModel)]="alertConfigInfo.thresholdLowValue" />
						</div>
					</div>
					<div class="col-md-6 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.temperature.high.threshold' | translate}}</span>	
							<input type="number" [disabled]="!editable"  [(ngModel)]="alertConfigInfo.thresholdHighValue" />
						</div>
					</div>
					<div class="col-md-12 aerial-field-section">
						<div class="aerial-field">
							<span class="aerial-sublabel aerial-color-blue">{{'aerial.user.notifications.temperature.device' | translate}}</span>	
							<select [(ngModel)]="alertConfigInfo.relatedDeviceId" [disabled]="!editable">
								<option [value]="" > </option> 
								<option [value]="device.deviceId" *ngFor="let device of userComplete.deviceList">{{device.name}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>