<div id="content">
	<div class="col-md-12 aerial-user-header-div-container">
		<div class="aerial-area-title" style="border-bottom: 1px solid #0066FF;padding-right: 0px;">
			<span class="material-icons aerial-area-icon" style="padding-left: 0px">perm_identity</span><span *ngIf="userDetailInfo" style="padding-left: 15px">{{userDetailInfo.user.fullName}}</span>
			<a href="#/portal/users" class="aerial-back-button" style="float:right;"><div class="material-icons" style="padding-top:2px;">chevron_left</div><div style="float:right;padding-top:5px;">{{'aerial.user.details.backToUserList' | translate}}</div></a>
		</div>
	</div>
	<div class="col-md-12 aerial-user-menu">
		<div class="aerial-submenu" *aerialProfile="'user'" [ngClass]="{'aerial-submenu-active': (section=='details')}" (click)="changeSubSection('details')"><i class="material-icons aerial-submenu-icon">perm_identity</i> <span class="aerial-submenu-label">{{'aerial.resident'|translate}}</span></div>
		<div class="aerial-submenu" *aerialProfile="'alerts'" [ngClass]="{'aerial-submenu-active': (section=='alerts')}" (click)="changeSubSection('alerts')"><i class="material-icons aerial-submenu-icon">warning_amber</i> <span class="aerial-submenu-label">{{'aerial.alerts'|translate}}</span></div>
		<div class="aerial-submenu" *aerialProfile="'devices'" [ngClass]="{'aerial-submenu-active': (section=='devices')}" (click)="changeSubSection('devices')"><i class="material-icons aerial-submenu-icon">devices_other</i> <span class="aerial-submenu-label">{{'aerial.devices'|translate}}</span></div>
		<div class="aerial-submenu" *aerialProfile="'activity'" [ngClass]="{'aerial-submenu-active': (section=='activity')}" (click)="changeSubSection('activity')"><i class="material-icons aerial-submenu-icon">directions_walk</i> <span class="aerial-submenu-label">{{'aerial.activiry'|translate}}</span></div>
		<div class="aerial-submenu" *aerialProfile="'sleep'" [ngClass]="{'aerial-submenu-active': (section=='sleep')}" (click)="changeSubSection('sleep')"><i class="material-icons aerial-submenu-icon">dark_mode</i> <span class="aerial-submenu-label">{{'aerial.user.sleep'|translate}}</span></div>
		<div class="aerial-submenu" *aerialProfile="'notifications'" [ngClass]="{'aerial-submenu-active': (section=='notifications')}" (click)="changeSubSection('notifications')"><i class="material-icons-outlined aerial-submenu-icon">notifications</i> <span class="aerial-submenu-label">{{'aerial.notifications'|translate}}</span></div>
	</div>
	<div class="col-md-12 aerial-user-body-div-container" *ngIf="section=='details'">
		<aerial-user-meta-edit [userComplete]="userDetailInfo"></aerial-user-meta-edit>
	</div>
	<div class="col-md-12 aerial-user-body-div-container" *ngIf="section=='alerts'">
		<aerial-user-meta [userComplete]="userDetailInfo"></aerial-user-meta>
		<div class="col-md-9">
			<aerial-user-alerts [userId]="userDetailInfo.user.id"></aerial-user-alerts>		
		</div>
	</div>
	<div class="col-md-12 aerial-user-body-div-container" *ngIf="section=='devices'">
		<aerial-user-meta [userComplete]="userDetailInfo"></aerial-user-meta>
		<div class="col-md-9">
			<div class="col-md-12" *ngIf="createHome" style="min-height: 35px">
				<button class="aerial-action-button pull-right" (click)="showCreateHome()"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">add_home_work</i> {{'aerial.register.home' | translate}}</button>
			</div>
			<div class="col-md-12" *ngIf="discoverDevicesEnable" style="min-height: 35px">
				<!-- AER-57 -->
				<button class="aerial-action-button pull-right" style="margin-right:20px;" (click)="disconnectHub()"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">remove_from_queue</i> {{'aerial.disconnect.hub' | translate}}</button>
				<!-- AER-57 end -->
				<button class="aerial-action-button pull-right" (click)="discoverDevices()"><i class="material-icons-outlined aerial-color-grey aerial-action-button-icon">screen_search_desktop</i> {{'aerial.discover.devices' | translate}}</button>
				<div class="pull-right" *ngIf="displayNoNewDevicesMsg" style="padding-top: 5px"><span class="aerial-integration-msg-info">{{'aerial.discoverDevices.noNewDevices' |  translate}}</span></div>
			</div>
			<div class="col-md-4" *ngFor="let device of userDetailInfo.deviceList">
				<aerial-devicebox [device]="device" [thirdPartLocationList]="thirdPartLocationList"></aerial-devicebox>
			</div>		
		</div>
	</div>
	<div class="col-md-12 aerial-user-body-div-container" *ngIf="section=='activity'">
		<aerial-user-meta [userComplete]="userDetailInfo"></aerial-user-meta>
		<div class="col-md-9">
			<aerial-user-activity [userId]="userDetailInfo.user.id"></aerial-user-activity>
		</div>
	</div>
	<div class="col-md-12 aerial-user-body-div-container" *ngIf="section=='sleep'">
		<aerial-user-meta [userComplete]="userDetailInfo"></aerial-user-meta>
		<div class="col-md-9">
			<aerial-user-sleep [userId]="userDetailInfo.user.id"></aerial-user-sleep>
		</div>
	</div>
	<div class="col-md-12 aerial-user-body-div-container" *ngIf="section=='notifications'">
		<aerial-user-meta [userComplete]="userDetailInfo"></aerial-user-meta>
		<div class="col-md-9">
			<aerial-user-notifications [userComplete]="userDetailInfo"></aerial-user-notifications>
		</div>
	</div>
	<div class="col-md-12 aerial-user-body-div-container" *ngIf="section=='createHome'">
		<div class="col-md-12">
			<button class="aerial-action-button pull-right"(click)="cancelRegisterHome()" [disabled]="!enableRegisterHomeBtn"><i class="material-icons aerial-color-red aerial-action-button-icon">close</i></button>
			<button class="aerial-action-button pull-right" (click)="saveRegisterHome()" [disabled]="!enableRegisterHomeBtn"><i class="material-icons aerial-color-blue aerial-action-button-icon">done</i></button>
		</div>
		<div class="col-md-12">
			<div class="col-md-6">
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-label">
						<span class="material-icons aerial-area-icon-16">add_home_work</span>
						<span class="aerial-area-icon-text-14">{{'aerial.register.home' | translate}}</span>
					</div>
					<div class="aerial-field">
						<span class="aerial-sublabel">{{'aerial.register.home.indications' | translate}}</span>	
					</div>
				</div>
				<div class="col-md-12 aerial-field-section">
					<div class="aerial-field">
						<span class="aerial-sublabel aerial-color-blue">{{'aerial.hub.mac' | translate}}:</span>
						<input type="text" [disabled]="!editableHub" [(ngModel)]="registerHomeInfo.hubMac">
						<div *ngIf="showHubError">
							<span class="aerial-integration-msg-error" *ngIf="!showGenericError">{{registerHomeResponseInfo.errorMsg | translate}}</span>
							<span class="aerial-integration-msg-error" *ngIf="showGenericError">{{'aerial.register.home.generic.error' | translate}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
